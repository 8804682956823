import React, { useEffect, useRef, useState } from 'react';
import Youtube from 'react-youtube';
import { useEventListener, useMenuHideWindow } from 'hooks';
import { isMobile } from 'react-device-detect';
import ViewOptions from "../";

const VideoView = (props) => {
    useMenuHideWindow(ViewOptions.video.id);

    const playerRef = React.createRef();
    const [paused, setPaused] = useState(false);
    const [calledReady, setCalledReady] = useState(false);

    const handlePauseClick = async () => {
        let state = await playerRef.current.internalPlayer.getPlayerState();
        if (state == 0) {
            playerRef.current.internalPlayer.seekTo(0, true);
            return
        }

        if (paused){
            playerRef.current.internalPlayer.playVideo();
            
        } else {
            playerRef.current.internalPlayer.pauseVideo();
        }
        setPaused(!paused)
      };

    const handleCenterClick = () => {
        if (isMobile) {
            handlePauseClick();
            return;
        }
        const getIframe = async () => {
            const iframe = await playerRef.current.internalPlayer.getIframe();
            return iframe;
        }
        getIframe().then((iframe) => {
            var requestFullScreen = iframe.requestFullScreen || iframe.mozRequestFullScreen || iframe.webkitRequestFullScreen;
            if (requestFullScreen) {
                requestFullScreen.bind(iframe)();
            }
        })
    }

    const handleBackClick = async () => {
        const currentTime = await playerRef.current.internalPlayer.getCurrentTime();

        playerRef.current.internalPlayer.seekTo(currentTime - 10, true);
    }

    const handleForwardClick = async () => {
        const currentTime = await playerRef.current.internalPlayer.getCurrentTime();

        playerRef.current.internalPlayer.seekTo(currentTime + 10, true);
    }
    
    useEventListener("pauseclick", handlePauseClick);
    useEventListener("centerclick", handleCenterClick);
    useEventListener("backclick", handleBackClick);
    useEventListener("forwardclick", handleForwardClick);


    const opts = {
        height: '260px',
        width: '100%',
        playerVars: {
          modestbranding: 1,
          controls: 2,
          autoplay: 1,
          fs: 0,
          playsinline: 1
        },
      }

    let id;
    props.videoId ? id = props.videoId : id = "3nmsprYu2F4";

    return (
        <Youtube videoId={id} opts={opts} ref={playerRef}/>
    )
}

export default VideoView;