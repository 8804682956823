import React, { useState, useRef, RefObject, useEffect } from 'react';
import styled from 'styled-components';

const FormFormat = styled.div`  

    input {
        color: white;
        background-color:transparent;
        border: 2px white;
        border-style: solid;
        border-width: 2px;
        border-radius: 10px;
        margin-top: 10px;
        outline: none;
        text-align: left;
        font-family: EditorialNew-Regular, serif;
        font-size: 40px;
        
        @media (max-width: 650px) {
            padding-left: 10px;
            padding-right: 10px;
            font-size: 30px; 
            }
          }

    }
  
    label {
        color: white;
        font-size: 40px;
        font-family: EditorialNew-Regular, serif;
        text-align: center;

        @media (max-width: 650px) {
            padding-left: 10px;
            padding-right: 10px;
            }
          }

    }


`;

const LaunchPassword = () => { 
    const password = 'ifthemusicisright';
    const [state, setState] = useState('');

    let input: string | undefined = undefined;

    const inputRef = useRef<HTMLInputElement>(null);

    const onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
       // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
       if (event.key === 'Enter') {
         event.preventDefault();
         event.stopPropagation();
         onSubmit();
       }
     }
 
     const onSubmit = (): void => {
        if (state == password) {
            dispatchEvent(new Event("successfulLogin"));
        }
     }

    const handleChange = (event: { target: { value: any; }; }) => {
        setState(event.target.value);
     }

    useEffect(() => {
        if (inputRef.current !== null) {
            inputRef.current.focus()
        }
    })

    return (
        <FormFormat>
            <form onSubmit={(event) => {event.preventDefault()}}> 
                <label htmlFor='password'> </label>
                <br />
                <input id ='password' placeholder='...' type='password' value={input} onChange={handleChange} onKeyDown={onKeyDown} ref={inputRef}/>
            </form>
        </FormFormat>
    )

} 

export default LaunchPassword;