import React from 'react';

import { PREVIEW } from 'App/previews';
import { SelectableList, SelectableListOption } from 'components';
import { useMenuHideWindow, useScrollHandler } from 'hooks';

import ViewOptions, {VideoView} from '../';
import MusicView from '../MusicView';

const DUKE_AND_THE_CASH_ID = "j961Twluzuk";
const CANNONBALL_ID = "b-1eRNMP66A";
const GONE_ID = "1_U7VTWlDaI";

const ShopView = () => {
  useMenuHideWindow(ViewOptions.shop.id);
  
  const options = [
    {
      label: "US Store",
      link: "https://merch.ambientinks.com/collections/babyboys",
      preview: PREVIEW.MUSIC
    },
    {
        label: "UK Store",
        link: "https://shop.transgressiverecords.com/artists/categories/baby-boys",
        preview: PREVIEW.MUSIC
    }
  ];
  const [index] = useScrollHandler(ViewOptions.shop.id, options);

  return <SelectableList options={options} activeIndex={index} />;
};

export default ShopView;
