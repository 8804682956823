//https://postimg.cc/gallery/4G7MYjL

const Artwork = [
    {artwork: "https://i.postimg.cc/XJpKzGs7/baby-boys-256x256-1.jpg"},
    {artwork: "https://i.postimg.cc/pV3C3CbN/baby-boys-256x256-2.jpg"},
    {artwork: "https://i.postimg.cc/yx0ZMFcB/baby-boys-256x256-4.jpg"},
    {artwork: "https://i.postimg.cc/Y95FDX4b/baby-boys-256x256-5.jpg"},
    {artwork: "https://i.postimg.cc/G2y8DkMY/baby-boys-256x256-6.jpg"},

    {artwork: "https://i.postimg.cc/25Nx3Sj0/baby-boys-256x256-12.jpg"},
    {artwork: "https://i.postimg.cc/PJ5yhVfh/baby-boys-256x256-15.jpg"},
    
    {artwork: "https://i.postimg.cc/DyWQgX8C/baby-boys-256x256-20.jpg"},
    {artwork: "https://i.postimg.cc/J0FN81dc/baby-boys-256x256-21.jpg"},
    {artwork: "https://i.postimg.cc/bvPQpVLM/baby-boys-256x256-22.jpg"},
    {artwork: "https://i.postimg.cc/vmX6MY4g/baby-boys-256x256-25.jpg"},
    {artwork: "https://i.postimg.cc/HxZ50YHD/baby-boys-256x256-27.jpg"},
    
    {artwork: "https://i.postimg.cc/SNz88bv3/baby-boys-256x256-30.jpg"},

    {artwork: "https://i.postimg.cc/bNQxWj9b/baby-boys-256x256-40.jpg"},
    {artwork: "https://i.postimg.cc/W4tJNRg5/baby-boys-256x256-47.jpg"},
    {artwork: "https://i.postimg.cc/cHN5qc84/baby-boys-256x256-49.jpg"},

    {artwork: "https://i.postimg.cc/yYBgJ30g/baby-boys-256x256-52.jpg"},
    {artwork: "https://i.postimg.cc/XJmKSjPK/baby-boys-256x256-55.jpg"},

    {artwork: "https://i.postimg.cc/nhgP2xFm/baby-boys-256x256-61.jpg"},
    {artwork: "https://i.postimg.cc/qRXDpC8c/baby-boys-256x256-65.jpg"},
    {artwork: "https://i.postimg.cc/NjB6wPdn/baby-boys-256x256-68.jpg"},

    {artwork: "https://i.postimg.cc/tCgvHJ7P/baby-boys-256x256-70.jpg"},
    {artwork: "https://i.postimg.cc/651j6KGP/baby-boys-256x256-72.jpg"},
    {artwork: "https://i.postimg.cc/yN3wCdQn/baby-boys-256x256-77.jpg"},

    {artwork: "https://i.postimg.cc/JhgHtTQM/baby-boys-256x256-84.jpg"},
    {artwork: "https://i.postimg.cc/ZYNpvhgg/baby-boys-256x256-86.jpg"},
]

export default Artwork;