import React from "react";
import { isMobile } from 'react-device-detect';
import "@google/model-viewer/dist/model-viewer-umd";

function Jailbreak() {

  let skybox;
  isMobile ? skybox = "4k_room.jpeg" : skybox = "16K_room.jpeg";

  return (
    <div id="holder">
      <model-viewer src="ipodclassic7.glb" ar ios-src="https://agile-crag-84223.herokuapp.com/https://storage.googleapis.com/cracked_wisdom/iPod_AR9.usdz" ar-scale="auto" camera-controls shadow-intensity="0" environment-image="https://agile-crag-84223.herokuapp.com/https://storage.googleapis.com/cracked_wisdom/HD_room.hdr" skybox-image={skybox} ios-skybox-image="4k_room.jpeg" shadow-softness="1" exposure="1" auto-rotate camera-orbit="360deg 91.4deg 0.554m" min-camera-orbit="auto auto auto" max-camera-orbit="auto auto 0.554m"></model-viewer>
    </div>
  );
}

export default Jailbreak