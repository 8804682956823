import React from 'react';

import { Unit } from 'components';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
  background-image: url("PlayPreview2.gif");
  background-position: center; 
  background-size: 170px 265px;
`;

const Image = styled.img`
  height: 10em;
  width: 10em;
  margin: ${Unit.XS};
`;

const Text = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const PlayPreview = () => {
  return (
    <Container>

    </Container>
  );
};

export default PlayPreview;
