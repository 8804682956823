import React, { useEffect } from 'react';

import { useMenuHideWindow } from 'hooks';
import styled from 'styled-components';

import ViewOptions from '../';
import Game from './Game';

const Canvas = styled.canvas`
  height: 100%;
  width: 100%;
  background-image: url("box-background4.jpg");
  background-repeat: no-repeat;
  background-position: center; 
  background-size: cover;
  );
`;

const BrickGame = () => {
  useMenuHideWindow(ViewOptions.brickGame.id);
  useEffect(() => {
    Game.init();
  }, []);

  return (
    <Canvas width="800" height="500" id="brickBreakerCanvas">
      <p>Your browser does not support this feature</p>
    </Canvas>
  );
};

export default BrickGame;
