import React from 'react';

import { PREVIEW } from 'App/previews';
import { SelectableList, SelectableListOption } from 'components';
import { useMenuHideWindow, useScrollHandler } from 'hooks';

import ViewOptions, {VideoView} from '../';
import MusicView from '../MusicView';

const DUKE_AND_THE_CASH_ID = "j961Twluzuk";
const CANNONBALL_ID = "b-1eRNMP66A";
const GONE_ID = "1_U7VTWlDaI";
const MAGGOT_ID = '3nmsprYu2F4';
const DESEPARO_ID = "BCLleU8G5RE";

const VideosView = () => {
  useMenuHideWindow(ViewOptions.videos.id);
  
  const options = [
    {
      label: "Cannonball",
      value: () => <VideoView videoId={CANNONBALL_ID}/>,
      viewId: ViewOptions.video.id,
      preview: PREVIEW.MUSIC
    },
    {
        label: "Duke And The Cash",
        value: () => <VideoView videoId={DUKE_AND_THE_CASH_ID}/>,
        viewId: ViewOptions.video.id,
        preview: PREVIEW.MUSIC
    },
    {
        label: "Gone",
        value: () => <VideoView videoId={GONE_ID}/>,
        viewId: ViewOptions.video.id,
        preview: PREVIEW.MUSIC
    },
    {
    label: "Desperado",
    value: () => <VideoView videoId={DESEPARO_ID}/>,
    viewId: ViewOptions.video.id,
    preview: PREVIEW.MUSIC
    },
    {
      label: "Maggot Water",
      value: () => <VideoView videoId={MAGGOT_ID}/>,
      viewId: ViewOptions.video.id,
      preview: PREVIEW.MUSIC
      },


  ];
  const [index] = useScrollHandler(ViewOptions.videos.id, options);

  return <SelectableList options={options} activeIndex={index} />;
};

export default VideosView;
