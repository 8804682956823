import React, { useState } from 'react';

import { ScrollWheel } from 'components';
import LaunchPassword  from 'components/LaunchPassword';
import WindowProvider, { LaunchWindowProvider } from 'services/window';
import styled, { createGlobalStyle } from 'styled-components';

import Interface, { LaunchInterface } from './Interface';
import { useEventListener } from 'hooks';

const GlobalStyles = createGlobalStyle`
   body {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 16px;
      box-sizing: border-box;
      background: #4f906c;
       }

    @font-face {
      font-family: 'EditorialNew-Regular';
      src: local('EditorialNew-Regular'), url(./fonts/EditorialNew-Regular.Otf) format('opentype');
      }
    
      ::-webkit-scrollbar { /** WebKit */
        display: none;
      }
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  background-image:url("BackgroundMic2.gif");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;

  @media (max-width: 650px) {
    background-image: none;
    }
  }
`;

const Shell = styled.div`
  position: relative;
  height: 100vh;
  margin: auto;
  max-height: 36.5em;
  width: 370px;
  border-radius: 30px;
  background: #4f906c;
  animation: descend 1.5s ease;
  box-shadow: inset 0 0 1em black;

  @media (max-width: 650px) {
    animation: none;
    border-radius: 0;
    height: 100vh;
    weight: 100vw;
    box-shadow: none;
    -webkit-box-reflect: unset;
    position: fixed;
    top: 0;
  }

  @keyframes descend {
    0% {
      transform: scale(0.3);
      opacity: 0;
    }

    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const App = () => {
  return (
    <Container>
      <GlobalStyles />
        <WindowProvider>
          <Shell>
            <Interface />
            <ScrollWheel />
          </Shell>
        </WindowProvider>
    </Container>
  );
};

export default App;

// Launch Page Variant

export const LaunchApp = () => {
  // Password Page Protection
 // const [children, setChildren] = useState((
 //   <>
  //  <GlobalStyles />
  //  <LaunchPassword />
 //   </>
 // ))

  const loadApp = () => {
    setChildren((
      <>
        <GlobalStyles />
        <LaunchWindowProvider>
          <Shell>
            <LaunchInterface />
            <ScrollWheel />
          </Shell>
        </LaunchWindowProvider>
      </>
    ))
  }
  useEventListener("successfulLogin", loadApp)

  // No Password
  const [children, setChildren] = useState((
        <>
        <GlobalStyles />
        <LaunchWindowProvider>
          <Shell>
            <LaunchInterface />
            <ScrollWheel />
          </Shell>
        </LaunchWindowProvider>
        </>
  ))

  return (
    <Container id="background">
      {children}
    </Container>
  );
};