import React from "react";
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
   body {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
      font-size: 16px;
      box-sizing: border-box;
      background: #4f906c;
       }

    @font-face {
      font-family: 'EditorialNew-Regular';
      src: local('EditorialNew-Regular'), url(./fonts/EditorialNew-Regular.Otf) format('opentype');
      }
    
      ::-webkit-scrollbar { /** WebKit */
        display: none;
      }
`;

const Container = styled.div`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;

  background:#4f906c;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  background-size: cover;

  @media (max-width: 650px) {
    background-image: none;
    }
  }

  input {
    display: block;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    color: #edce1a;
    background-color:transparent;
    border: 2px #edce1a;
    border-style: solid;
    border-width: 2px;
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 10px;
    outline: none;
    text-align: center;
    font-family: EditorialNew-Regular, serif;
    font-size: 30px;
    padding-left: 15px;
    padding-right: 15px;
 
    @media (max-width: 650px) {
    padding-top: 1%;
    }
}

img {
  display: flex;
  height: 30vh;
  shadow: 10px;
  align-items: center;
  justify-content: center;
  background-color:transparent;
  margin-top: 20px;
`;

function Livestream() {

    function handleChange() {

    }
    //Scott put your html in the return
    return (
      <Container>
        <div id="mc_embed_signup" styles={{background: "#fff", clear: "left", font: 14 + "px Helvetica,Arial,sans-serif" }}>
          <form action="https://theglowmgmt.us17.list-manage.com/subscribe/post?u=1143ef2ae4c2f267d492eb9cc&amp;id=f4630feaf8" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
            <div id="mc_embed_signup_scroll">
              <img id="ticket" src="admit-one6.png" alt="ticket"></img><br></br>
              <input type="email" name="EMAIL" class="email" id="mce-EMAIL" placeholder="email address" required></input>
              <div styles={{position: "absolute", left: "-5000px"}} aria-hidden="true"><input type="hidden" name="b_1143ef2ae4c2f267d492eb9cc_f4630feaf8" tabindex="-1" value=""></input></div>
              <div class="clear"><input type="submit" value="RSVP" name="subscribe" id="mc-embedded-subscribe" class="button"></input></div>
            </div>
          </form>
        </div>
      </Container>
    );
}

export default Livestream;