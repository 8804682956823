import React, { useCallback, useEffect, useState } from 'react';

import { PREVIEW } from 'App/previews';
import ViewOptions, {
  CoverFlowView,
  GamesView,
  AboutView,
  VideosView,
  ShopView
} from 'App/views';
import { SelectableList, SelectableListOption } from 'components';
import { useScrollHandler } from 'hooks';


const HomeView = () => {
  const initialOptions = [
    {
      label: "Listen",
      link: 'https://babyboys.lnk.to/threesome',
      preview: PREVIEW.DUKE
    },
    {
      label: "Watch",
      value: () => <VideosView />,
      viewId: ViewOptions.videos.id,
      preview: PREVIEW.CANNONBALL
    },
    {
      label: "Play",
      value: () => <GamesView />,
      viewId: ViewOptions.games.id,
      preview: PREVIEW.PLAY
    },
    {
      label: "Peep",
      value: () => <CoverFlowView />,
      viewId: ViewOptions.coverFlow.id,
      preview: PREVIEW.PEEP
    },
    {
      label: "Shop",
      value: () => <ShopView />,
      viewId: ViewOptions.shop.id,
      preview: PREVIEW.SHOP
    },
    {
      label: "Follow",
      value: () => <AboutView />,
      viewId: ViewOptions.about.id,
      preview: PREVIEW.FOLLOW
    }
  ];

  const [options, setOptions] = useState(initialOptions);
  const [index] = useScrollHandler(ViewOptions.home.id, options);

  return <SelectableList options={options} activeIndex={index} />;
};

export default HomeView;
