import React from 'react';

import CannonballPreview from './CannonballPreview';
import DukePreview from "./DukePreview";
import MusicPreview from './MusicPreview';
import NowPlayingPreview from './NowPlayingPreview';
import SettingsPreview from './SettingsPreview';
import PhotosPreview from './PhotosPreview';
import PeepPreview from "./PeepPreview";
import PlayPreview from "./PlayPreview";
import FollowPreview from "./FollowPreview";
import ShopPreview from "./ShopPreview";


export enum PREVIEW {
  MUSIC = "music",
  SETTINGS = "settings",
  NOW_PLAYING = "nowPlaying",
  PHOTOS = "photos",
  CANNONBALL = "cannonball",
  DUKE = "duke",
  PEEP = "peep",
  PLAY = "play",
  FOLLOW = "follow",
  SHOP = "shop"
}

export const Previews = {
  [PREVIEW.MUSIC]: () => <DukePreview />,
  [PREVIEW.CANNONBALL]: () => <CannonballPreview />,
  [PREVIEW.DUKE]: () => <DukePreview />,
  [PREVIEW.SETTINGS]: () => <SettingsPreview />,
  [PREVIEW.NOW_PLAYING]: () => <NowPlayingPreview />,
  [PREVIEW.PHOTOS] : () => <DukePreview />, //TODO Fix PhotoPreview
  [PREVIEW.PEEP]: () => <PeepPreview />,
  [PREVIEW.PLAY]: () => <PlayPreview />,
  [PREVIEW.FOLLOW]: () => <FollowPreview />,
  [PREVIEW.SHOP]: () => <ShopPreview />
};
