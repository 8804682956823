export { default as AboutView } from "./AboutView";
export { default as CoverFlowView } from "./CoverFlowView";
export { default as HomeView } from "./HomeView";
export { default as LaunchView } from "./LaunchView";
export { default as VideoView } from "./VideoView";
export { default as VideosView } from "./VideosView";
export { default as MusicView } from "./MusicView";
export { default as ArtistView } from "./ArtistView";
export { default as AlbumView } from "./AlbumView";
export { default as NowPlayingView } from "./NowPlayingView";
export { default as GamesView } from "./GamesView";
export { default as BrickGameView } from "./BrickGameView";
export { default as SettingsView } from "./SettingsView";
export { default as SocialView } from "./SocialView";
export { default as ShopView } from "./ShopView";

export enum WINDOW_TYPE {
  SPLIT = "SPLIT",
  FULL = "FULL",
  COVER_FLOW = "COVER_FLOW",
  NO_HEADER = "NO_HEADER"
}

type ViewOption = {
  id: string;
  title: string;
  type: WINDOW_TYPE;
};

export const ViewOptions: Record<string, ViewOption> = {
  // Split Screen Views
  home: { id: "home", title: "babyboys.sucks", type: WINDOW_TYPE.SPLIT },
  music: { id: "music", title: "Songs", type: WINDOW_TYPE.SPLIT },
  settings: { id: "settings", title: "Settings", type: WINDOW_TYPE.SPLIT },

  // Fullscreen Views
  about: { id: "about", title: "About", type: WINDOW_TYPE.FULL },
  shop: { id: "shop", title: "Shop", type:WINDOW_TYPE.FULL},
  games: { id: "games", title: "Extras", type: WINDOW_TYPE.FULL },
  artists: { id: "artists", title: "Artists", type: WINDOW_TYPE.FULL },
  artist: { id: "artist", title: "Artist", type: WINDOW_TYPE.FULL },
  albums: { id: "albums", title: "Albums", type: WINDOW_TYPE.FULL },
  album: { id: "album", title: "Album", type: WINDOW_TYPE.FULL },
  launch: { id: "launch", title: "babyboys.sucks", type: WINDOW_TYPE.FULL },
  social: {id: "social", title: "Social Media", type: WINDOW_TYPE.FULL},
  nowPlaying: {
    id: "nowPlaying",
    title: "Now Playing",
    type: WINDOW_TYPE.FULL
  },
  brickGame: { id: "brickGame", title: "Cannonball", type: WINDOW_TYPE.FULL },
  video: { id: "video", title: "Video", type: WINDOW_TYPE.FULL},
  stream: { id: "stream", title: "Spotify", type:WINDOW_TYPE.FULL },
  videos: {id: "videos", title: "Videos", type: WINDOW_TYPE.FULL},

  // CoverFlow view
  coverFlow: {
    id: "coverFlow",
    title: "Photos",
    type: WINDOW_TYPE.COVER_FLOW
  },

  // Headerless View
};

export default ViewOptions;
