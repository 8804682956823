import React, { useCallback, useEffect, useState } from 'react';

import { PREVIEW } from 'App/previews';
import ViewOptions, {
  WINDOW_TYPE,
} from 'App/views';
import { SelectableList, SelectableListOption } from 'components';
import { useScrollHandler, useMenuHideWindow } from 'hooks';

const SocialView = () => {
    useMenuHideWindow(ViewOptions.social.id);


  const initialOptions = [
       {
        label: "Instagram",
        link: "https://www.instagram.com/babyboyssucks/",
        value: "Instagram",
        preview: PREVIEW.PHOTOS

    },
    {
      label: "Twitter",
      link: "https://twitter.com/babyboyssucks",
      value: "Twitter",
      preview: PREVIEW.PHOTOS
    },
    {
        label: "YouTube",
        link: "https://www.youtube.com/channel/UCeybb93cXGVyVuUm1wqMWOQ",
        value: "YouTube",
        preview: PREVIEW.PHOTOS
    },
    {
        label: "Bandcamp",
        link: "https://babyboys.bandcamp.com/",
        value: "Bandcamp",
        preview: PREVIEW.PHOTOS
    },
    {
        label: "Spotify",
        link: "https://open.spotify.com/artist/5h1aEtZ8g7GdavtgSg633u",
        value: "Spotify",
        preview: PREVIEW.PHOTOS
    },
    {
        label: "Apple Music",
        link: "https://music.apple.com/gb/artist/baby-boys/1447881532",
        value: "Apple Music",
        preview: PREVIEW.PHOTOS
    },
  ];
  
  const [options, setOptions] = useState(initialOptions);
  const [index] = useScrollHandler(ViewOptions.social.id, options);

  return <SelectableList options={options} activeIndex={index} />;
};

export default SocialView;